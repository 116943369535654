.DateRangePickerInput {
    display: flex;
    border-radius: 4px;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 3px;
}

.DateInput {
    width: 100%;
    min-width: 50px;
}